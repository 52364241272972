import {Component, EventEmitter, Input, Output} from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'list-meme',
  templateUrl: './list-meme.component.html',
  styleUrls: ['./list-meme.component.css']
})
export class ListMemeComponent{
  @Input() listMeme: any = [];
  @Input() total = 0;
  @Output() onShowMore: EventEmitter<void> = new EventEmitter<void>();
  apiUrl: string = environment.apiUrl;

  constructor(
  ) {
  }

  showMore() {
    this.onShowMore.emit();
  }
}
