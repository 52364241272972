import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2
} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {TagService} from "./services/tag.service";
import {Router} from "@angular/router";
import {TAG_TYPE} from "./constants/config";
import {DeviceService} from "./services/device.service";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  private IQSetting: any;
  listTag: any = [];
  listTagHot: any = [];
  formData: FormGroup;
  fb: FormBuilder = new FormBuilder();

  constructor(
    private tagService: TagService,
    private deviceService: DeviceService,
    private router: Router,
    private renderer: Renderer2,
    private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.formData = this.fb.group({
      searchText: [''],
    });
  }

  async ngOnInit() {
    await this.searchListTag();
  }

  ngAfterViewInit() {
    this.resizePlugins();
    const sidebarToggleBtn = this.el.nativeElement.querySelectorAll('[data-toggle="sidebar"]');
    Array.from(sidebarToggleBtn, (sidebarBtn: HTMLElement) => {
      this.sidebarToggle(sidebarBtn);
    });
    const sidebarBody = this.el.nativeElement.querySelector('.sidebar-body');
    if (!this.isMobile()) {
      setTimeout(() => {
        if (!sidebarBody.classList.contains('position-fixed')) {
          this.renderer.addClass(sidebarBody, 'position-fixed');
        }
      }, 400);
    }
  }

  async searchListTag() {
    await this.tagService.searchList({type: TAG_TYPE.MEME}).then((res) => {
      if (res?.status) {
        this.listTag = res.data;
        this.listTagHot = this.listTag.filter((x: any) => x.is_hot == "1");
      }
    });
  }

  searchMeme() {
    this.closeSidebar();
    if (this.formData.get('searchText')?.value) {
      this.router.navigateByUrl(`/search/${this.formData.get('searchText')?.value}`, {state: {}});
    }
  }

  isMobile() {
    return this.deviceService.isMobile();
  }

  //Sidebar config

  closeSidebar() {
    if (this.isMobile()) {
      const sidebar = this.el.nativeElement.querySelector('.sidebar');
      if (!sidebar.classList.contains('sidebar-mini')) {
        this.renderer.addClass(sidebar, 'sidebar-mini');
        this.updateSidebarType();
      }
    }
  }

  updateSidebarType() {
    if (typeof this.IQSetting !== typeof undefined) {
      const sidebarType = this.IQSetting.options.setting.sidebar_type.value;
      const newTypes = sidebarType;
      if (sidebarType.includes('sidebar-mini')) {
        const indexOf = newTypes.findIndex((x: string) => x === 'sidebar-mini');
        newTypes.splice(indexOf, 1);
      } else {
        newTypes.push('sidebar-mini');
      }
      this.IQSetting.sidebar_type(newTypes);
    }
  }

  sidebarToggle(elem: HTMLElement) {
    elem.addEventListener('click', (e: Event) => {
      const sidebar = this.el.nativeElement.querySelector('.sidebar');
      const sidebarBody = this.el.nativeElement.querySelector('.sidebar-body');
      if (sidebar.classList.contains('sidebar-mini')) {
        this.renderer.removeClass(sidebar, 'sidebar-mini');
        if (!this.isMobile()) {
          setTimeout(() => {
            if (!sidebarBody.classList.contains('position-fixed')) {
              this.renderer.addClass(sidebarBody, 'position-fixed');
            }
          }, 400);
        }
        this.updateSidebarType();
      } else {
        this.renderer.addClass(sidebar, 'sidebar-mini');
        if (sidebarBody.classList.contains('position-fixed')) {
          if (!this.isMobile()) this.renderer.removeClass(sidebarBody, 'position-fixed');
        }
        this.updateSidebarType();
      }
    });
  }

  resizePlugins() {
    if (isPlatformBrowser(this.platformId)) {
      const sidebarResponsive = this.el.nativeElement.querySelector('[data-sidebar="responsive"]');
      if (window.innerWidth < 1025) {
        if (sidebarResponsive !== null) {
          if (!sidebarResponsive.classList.contains('sidebar-mini')) {
            this.renderer.addClass(sidebarResponsive, 'sidebar-mini');
            this.renderer.addClass(sidebarResponsive, 'on-resize');
          }
        }
      } else {
        if (sidebarResponsive !== null) {
          if (sidebarResponsive.classList.contains('sidebar-mini') && sidebarResponsive.classList.contains('on-resize')) {
            this.renderer.removeClass(sidebarResponsive, 'sidebar-mini');
            this.renderer.removeClass(sidebarResponsive, 'on-resize');
          }
        }
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.resizePlugins();
  }
}
