<div class="row">
  <div class="col-lg-9">
    <div class="col-sm-12">
      <div class="card card-block card-stretch mb-0">
        <div class="card-header d-flex justify-content-between">
          <div class="header-title">
            <h4 class="card-title fw-bold">Tag #{{ tagDetail?.name }} ({{ totalRecord }})</h4>
          </div>
        </div>
      </div>
    </div>
    <list-meme *ngIf="tagDetail?.type == TAG_TYPE.MEME" [listMeme]="listItem" [total]="totalRecord"
               (onShowMore)="searchPageItem(false)"></list-meme>
    <list-news *ngIf="tagDetail?.type == TAG_TYPE.NEWS" [listNews]="listItem" [total]="totalRecord"
               (onShowMore)="searchPageItem(false)"></list-news>
  </div>
  <div class="col-lg-3">
    <list-meme-related *ngIf="tagDetail?.type == TAG_TYPE.MEME" [listMemeRelated]="listItemHot"
                       [title]="'Meme Hot'"></list-meme-related>
    <list-news-related *ngIf="tagDetail?.type == TAG_TYPE.NEWS" [listNewsRelated]="listItemHot"
                       [title]="'Tin tức Hot'"></list-news-related>
  </div>
</div>
