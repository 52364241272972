import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(
    private meta: Meta,
    private titleService: Title) {
  }

  setMetaHome() {
    this.titleService.setTitle('Tải Ảnh Meme Hài Hước - Hàng Ngày Cập Nhật | Meme Chúa');
    this.meta.updateTag({ name: 'author', content: 'mtuanv, ductinhv, haianhphung' });
    this.meta.updateTag({ name: 'keywords', content: 'Meme chúa, Meme hay, Meme hài, Meme bựa, Meme vui, Meme mèo, Meme chó, Meme buồn, Meme giang hồ, Meme Dark, Meme Tiến Bịp, Meme Khá Bảnh, Meme dễ thương, Meme ếch, Meme gấu trúc, Meme cute, Meme Chua' });
    this.meta.updateTag({ name: 'description', content: 'Khám phá và tải xuống những bức ảnh meme hài hước mỗi ngày từ trang chủ của chúng tôi. Duyệt qua bộ sưu tập đa dạng và độc đáo, với công cụ tìm kiếm tiện lợi.' });
    this.meta.updateTag({ property: 'og:title', content: 'Tải Ảnh Meme Hài Hước - Hàng Ngày Cập Nhật | Meme Chúa' });
    this.meta.updateTag({ property: 'og:description', content: 'Khám phá và tải xuống những bức ảnh meme hài hước mỗi ngày từ trang chủ của chúng tôi. Duyệt qua bộ sưu tập đa dạng và độc đáo, với công cụ tìm kiếm tiện lợi.' });
    this.meta.updateTag({ property: 'og:image', content: 'https://memechua.com/assets/images/meme_logo.png' });
    this.meta.updateTag({ property: 'og:image:secure_url', content: 'https://memechua.com/assets/images/meme_logo.png' });
    this.meta.updateTag({ property: 'og:url', content: 'https://memechua.com' });
    this.meta.updateTag({ property: 'twitter:title', content: 'Tải Ảnh Meme Hài Hước - Hàng Ngày Cập Nhật | Meme Chúa' });
    this.meta.updateTag({ property: 'twitter:image', content: 'https://memechua.com/assets/images/meme_logo.png' });
    this.meta.updateTag({ property: 'twitter:description', content: 'Khám phá và tải xuống những bức ảnh meme hài hước mỗi ngày từ trang chủ của chúng tôi. Duyệt qua bộ sưu tập đa dạng và độc đáo, với công cụ tìm kiếm tiện lợi.' });
    this.meta.updateTag({ property: 'twitter:url', content: 'https://memechua.com' });
  }

  setMetaMeme(name: any, slug: any, imgUrl: any) {
    this.titleService.setTitle(`${name.charAt(0).toUpperCase() + name.slice(1)} - Ảnh Meme Hài Hước | Meme Chúa`);
    this.meta.updateTag({ name: 'author', content: 'mtuanv, ductinhv, haianhphung' });
    this.meta.updateTag({ name: 'keywords', content: 'Meme chúa, Meme hay, Meme hài, Meme bựa, Meme vui, Meme mèo, Meme chó, Meme buồn, Meme giang hồ, Meme Dark, Meme Tiến Bịp, Meme Khá Bảnh, Meme dễ thương, Meme ếch, Meme gấu trúc, Meme cute, Meme Chua' });
    this.meta.updateTag({ name: 'description', content: `Meme ${name.charAt(0).toUpperCase() + name.slice(1)}. Xem, tải xuống và chia sẻ bức ảnh này. Khám phá thêm nhiều meme khác trên trang web của chúng tôi.` });
    this.meta.updateTag({ property: 'og:title', content: `${name.charAt(0).toUpperCase() + name.slice(1)} - Ảnh Meme Hài Hước | Meme Chúa` });
    this.meta.updateTag({ property: 'og:description', content: `Meme ${name.charAt(0).toUpperCase() + name.slice(1)}. Xem, tải xuống và chia sẻ bức ảnh này. Khám phá thêm nhiều meme khác trên trang web của chúng tôi.` });
    this.meta.updateTag({ property: 'og:image', content: imgUrl });
    this.meta.updateTag({ property: 'og:image:secure_url', content: imgUrl });
    this.meta.updateTag({ property: 'og:url', content: `https://memechua.com/meme/${slug}` });
    this.meta.updateTag({ property: 'twitter:title', content: `${name.charAt(0).toUpperCase() + name.slice(1)} - Ảnh Meme Hài Hước | Meme Chúa` });
    this.meta.updateTag({ property: 'twitter:image', content: imgUrl });
    this.meta.updateTag({ property: 'twitter:description', content: `Meme ${name.charAt(0).toUpperCase() + name.slice(1)}. Xem, tải xuống và chia sẻ bức ảnh này. Khám phá thêm nhiều meme khác trên trang web của chúng tôi.` });
    this.meta.updateTag({ property: 'twitter:url', content: `https://memechua.com/meme/${slug}` });
  }

  setMetaNewsList() {
    this.titleService.setTitle('Tin Tức | Meme Chúa');
    this.meta.updateTag({ name: 'author', content: 'mtuanv, ductinhv, haianhphung' });
    this.meta.updateTag({ name: 'keywords', content: 'Meme chúa, Tin tức, Gen Z' });
    this.meta.updateTag({ name: 'description', content: 'Kênh tin tức danh cho giới trẻ. Đọc thêm để khám phá nhiều thông tin thú vị trên trang web của chúng tôi.' });
    this.meta.updateTag({ property: 'og:title', content: 'Tin Tức | Meme Chúa' });
    this.meta.updateTag({ property: 'og:description', content: 'Kênh tin tức danh cho giới trẻ. Đọc thêm để khám phá nhiều thông tin thú vị trên trang web của chúng tôi.' });
    this.meta.updateTag({ property: 'og:image', content: 'https://memechua.com/assets/images/meme_logo.png' });
    this.meta.updateTag({ property: 'og:image:secure_url', content: 'https://memechua.com/assets/images/meme_logo.png' });
    this.meta.updateTag({ property: 'og:url', content: 'https://memechua.com/tin-tuc' });
    this.meta.updateTag({ property: 'twitter:title', content: 'Tin Tức | Meme Chúa' });
    this.meta.updateTag({ property: 'twitter:image', content: 'https://memechua.com/assets/images/meme_logo.png' });
    this.meta.updateTag({ property: 'twitter:description', content: 'Kênh tin tức danh cho giới trẻ. Đọc thêm để khám phá nhiều thông tin thú vị trên trang web của chúng tôi.' });
    this.meta.updateTag({ property: 'twitter:url', content: 'https://memechua.com/tin-tuc' });
  }

  setMetaNewsDetail(name: any, slug: any, imgUrl: any) {
    this.titleService.setTitle(`${name.charAt(0).toUpperCase() + name.slice(1)} - Tin Tức | Meme Chúa`);
    this.meta.updateTag({ name: 'author', content: 'mtuanv, ductinhv, haianhphung' });
    this.meta.updateTag({ name: 'keywords', content: 'Meme chúa, Tin tức, Gen Z' });
    this.meta.updateTag({ name: 'description', content: `${name.charAt(0).toUpperCase() + name.slice(1)}. Xem và chia sẻ bài viết này. Đọc thêm để khám phá nhiều thông tin thú vị trên trang web của chúng tôi.` });
    this.meta.updateTag({ property: 'og:title', content: `${name.charAt(0).toUpperCase() + name.slice(1)} - Tin Tức | Meme Chúa` });
    this.meta.updateTag({ property: 'og:description', content: `${name.charAt(0).toUpperCase() + name.slice(1)}. Xem và chia sẻ bài viết này. Đọc thêm để khám phá nhiều thông tin thú vị trên trang web của chúng tôi.` });
    this.meta.updateTag({ property: 'og:image', content: imgUrl });
    this.meta.updateTag({ property: 'og:image:secure_url', content: imgUrl });
    this.meta.updateTag({ property: 'og:url', content: `https://memechua.com/tin-tuc/${slug}` });
    this.meta.updateTag({ property: 'twitter:title', content: `${name.charAt(0).toUpperCase() + name.slice(1)} - Tin Tức | Meme Chúa` });
    this.meta.updateTag({ property: 'twitter:image', content: imgUrl });
    this.meta.updateTag({ property: 'twitter:description', content: `${name.charAt(0).toUpperCase() + name.slice(1)}. Xem và chia sẻ bài viết này. Đọc thêm để khám phá nhiều thông tin thú vị trên trang web của chúng tôi.` });
    this.meta.updateTag({ property: 'twitter:url', content: `https://memechua.com/tin-tuc/${slug}` });
  }

  setMetaTag(name: any, slug: any) {
    this.titleService.setTitle(`Tag ${name.charAt(0).toUpperCase() + name.slice(1)}. Hình ảnh hài hước về '${name.charAt(0).toUpperCase() + name.slice(1)}' | Meme Chúa`);
    this.meta.updateTag({ name: 'author', content: 'mtuanv, ductinhv, haianhphung' });
    this.meta.updateTag({ name: 'keywords', content: 'Meme chúa, Meme hay, Meme hài, Meme bựa, Meme vui, Meme mèo, Meme chó, Meme buồn, Meme giang hồ, Meme Dark, Meme Tiến Bịp, Meme Khá Bảnh, Meme dễ thương, Meme ếch, Meme gấu trúc, Meme cute, Meme Chua' });
    this.meta.updateTag({ name: 'description', content: `Tag ${name.charAt(0).toUpperCase() + name.slice(1)}. Xem, tải xuống và chia sẻ những bức ảnh hài hước về '${name.charAt(0).toUpperCase() + name.slice(1)}'.` });
    this.meta.updateTag({ property: 'og:title', content: `Tag ${name.charAt(0).toUpperCase() + name.slice(1)}. Hình ảnh hài hước về '${name.charAt(0).toUpperCase() + name.slice(1)}' | Meme Chúa` });
    this.meta.updateTag({ property: 'og:description', content: `Tag ${name.charAt(0).toUpperCase() + name.slice(1)}. Xem, tải xuống và chia sẻ những bức ảnh hài hước về '${name.charAt(0).toUpperCase() + name.slice(1)}'.` });
    this.meta.updateTag({ property: 'og:image', content: 'https://memechua.com/assets/images/meme_logo.png' });
    this.meta.updateTag({ property: 'og:image:secure_url', content: 'https://memechua.com/assets/images/meme_logo.png' });
    this.meta.updateTag({ property: 'og:url', content: `https://memechua.com/tag/${slug}` });
    this.meta.updateTag({ property: 'twitter:title', content: `Tag ${name.charAt(0).toUpperCase() + name.slice(1)}. Hình ảnh hài hước về '${name.charAt(0).toUpperCase() + name.slice(1)}' | Meme Chúa` });
    this.meta.updateTag({ property: 'twitter:image', content: 'https://memechua.com/assets/images/meme_logo.png' });
    this.meta.updateTag({ property: 'twitter:description', content: `Tag ${name.charAt(0).toUpperCase() + name.slice(1)}. Xem, tải xuống và chia sẻ những bức ảnh hài hước về '${name.charAt(0).toUpperCase() + name.slice(1)}'.` });
    this.meta.updateTag({ property: 'twitter:url', content: `https://memechua.com/tag/${slug}` });
  }

  setMetaSearch(name: any) {
    this.titleService.setTitle(`Kết quả tìm kiếm cho: ${name.charAt(0).toUpperCase() + name.slice(1)} | Meme Chúa`);
    this.meta.updateTag({ name: 'author', content: 'mtuanv, ductinhv, haianhphung' });
    this.meta.updateTag({ name: 'keywords', content: 'Meme chúa, Meme hay, Meme hài, Meme bựa, Meme vui, Meme mèo, Meme chó, Meme buồn, Meme giang hồ, Meme Dark, Meme Tiến Bịp, Meme Khá Bảnh, Meme dễ thương, Meme ếch, Meme gấu trúc, Meme cute, Meme Chua' });
    this.meta.updateTag({ name: 'description', content: `Khám phá những hình ảnh hài hước về '${name.charAt(0).toUpperCase() + name.slice(1)}'. Xem, tải xuống và chia sẻ bức ảnh này. Khám phá thêm nhiều meme khác trên trang web của chúng tôi.` });
    this.meta.updateTag({ property: 'og:title', content: `Kết quả tìm kiếm cho: ${name.charAt(0).toUpperCase() + name.slice(1)} | Meme Chúa` });
    this.meta.updateTag({ property: 'og:description', content: `Khám phá những hình ảnh hài hước về '${name.charAt(0).toUpperCase() + name.slice(1)}'. Xem, tải xuống và chia sẻ bức ảnh này. Khám phá thêm nhiều meme khác trên trang web của chúng tôi.` });
    this.meta.updateTag({ property: 'og:image', content: 'https://memechua.com/assets/images/meme_logo.png' });
    this.meta.updateTag({ property: 'og:image:secure_url', content: 'https://memechua.com/assets/images/meme_logo.png' });
    this.meta.updateTag({ property: 'og:url', content: `https://memechua.com/search/${name.charAt(0).toUpperCase() + name.slice(1)}` });
    this.meta.updateTag({ property: 'twitter:title', content: `Kết quả tìm kiếm cho: ${name.charAt(0).toUpperCase() + name.slice(1)} | Meme Chúa` });
    this.meta.updateTag({ property: 'twitter:image', content: 'https://memechua.com/assets/images/meme_logo.png' });
    this.meta.updateTag({ property: 'twitter:description', content: `Khám phá những hình ảnh hài hước về '${name.charAt(0).toUpperCase() + name.slice(1)}'. Xem, tải xuống và chia sẻ bức ảnh này. Khám phá thêm nhiều meme khác trên trang web của chúng tôi.` });
    this.meta.updateTag({ property: 'twitter:url', content: `https://memechua.com/search/${name.charAt(0).toUpperCase() + name.slice(1)}` });
  }

  setMetaContact() {
    this.titleService.setTitle('Liên Hệ | Meme Chúa');
    this.meta.updateTag({ name: 'author', content: 'mtuanv, ductinhv, haianhphung' });
    this.meta.updateTag({ name: 'keywords', content: 'Meme chúa, Meme hay, Meme hài, Meme bựa, Meme vui, Meme mèo, Meme chó, Meme buồn, Meme giang hồ, Meme Dark, Meme Tiến Bịp, Meme Khá Bảnh, Meme dễ thương, Meme ếch, Meme gấu trúc, Meme cute, Meme Chua' });
    this.meta.updateTag({ name: 'description', content: 'Khám phá và tải xuống những bức ảnh meme hài hước mỗi ngày từ trang chủ của chúng tôi. Duyệt qua bộ sưu tập đa dạng và độc đáo, với công cụ tìm kiếm tiện lợi.' });
    this.meta.updateTag({ property: 'og:title', content: 'Liên Hệ | Meme Chúa' });
    this.meta.updateTag({ property: 'og:description', content: 'Khám phá và tải xuống những bức ảnh meme hài hước mỗi ngày từ trang chủ của chúng tôi. Duyệt qua bộ sưu tập đa dạng và độc đáo, với công cụ tìm kiếm tiện lợi.' });
    this.meta.updateTag({ property: 'og:image', content: 'https://memechua.com/assets/images/meme_logo.png' });
    this.meta.updateTag({ property: 'og:image:secure_url', content: 'https://memechua.com/assets/images/meme_logo.png' });
    this.meta.updateTag({ property: 'og:url', content: 'https://memechua.com/contact' });
    this.meta.updateTag({ property: 'twitter:title', content: 'Liên Hệ | Meme Chúa' });
    this.meta.updateTag({ property: 'twitter:image', content: 'https://memechua.com/assets/images/meme_logo.png' });
    this.meta.updateTag({ property: 'twitter:description', content: 'Khám phá và tải xuống những bức ảnh meme hài hước mỗi ngày từ trang chủ của chúng tôi. Duyệt qua bộ sưu tập đa dạng và độc đáo, với công cụ tìm kiếm tiện lợi.' });
    this.meta.updateTag({ property: 'twitter:url', content: 'https://memechua.com/contact' });
  }

  setMetaTermsPrivacy() {
    this.titleService.setTitle('Điều khoản & Quyền riêng tư | Meme Chúa');
    this.meta.updateTag({ name: 'author', content: 'mtuanv, ductinhv, haianhphung' });
    this.meta.updateTag({ name: 'keywords', content: 'Meme chúa, Meme hay, Meme hài, Meme bựa, Meme vui, Meme mèo, Meme chó, Meme buồn, Meme giang hồ, Meme Dark, Meme Tiến Bịp, Meme Khá Bảnh, Meme dễ thương, Meme ếch, Meme gấu trúc, Meme cute, Meme Chua' });
    this.meta.updateTag({ name: 'description', content: 'Khám phá và tải xuống những bức ảnh meme hài hước mỗi ngày từ trang chủ của chúng tôi. Duyệt qua bộ sưu tập đa dạng và độc đáo, với công cụ tìm kiếm tiện lợi.' });
    this.meta.updateTag({ property: 'og:title', content: 'Điều khoản & Quyền riêng tư | Meme Chúa' });
    this.meta.updateTag({ property: 'og:description', content: 'Khám phá và tải xuống những bức ảnh meme hài hước mỗi ngày từ trang chủ của chúng tôi. Duyệt qua bộ sưu tập đa dạng và độc đáo, với công cụ tìm kiếm tiện lợi.' });
    this.meta.updateTag({ property: 'og:image', content: 'https://memechua.com/assets/images/meme_logo.png' });
    this.meta.updateTag({ property: 'og:image:secure_url', content: 'https://memechua.com/assets/images/meme_logo.png' });
    this.meta.updateTag({ property: 'og:url', content: 'https://memechua.com/terms-privacy' });
    this.meta.updateTag({ property: 'twitter:title', content: 'Điều khoản & Quyền riêng tư | Meme Chúa' });
    this.meta.updateTag({ property: 'twitter:image', content: 'https://memechua.com/assets/images/meme_logo.png' });
    this.meta.updateTag({ property: 'twitter:description', content: 'Khám phá và tải xuống những bức ảnh meme hài hước mỗi ngày từ trang chủ của chúng tôi. Duyệt qua bộ sưu tập đa dạng và độc đáo, với công cụ tìm kiếm tiện lợi.' });
    this.meta.updateTag({ property: 'twitter:url', content: 'https://memechua.com/terms-privacy' });
  }

  setMetaNotFound() {
    this.titleService.setTitle('Trang không tìm thấy | Meme Chúa');
    this.meta.updateTag({ name: 'author', content: 'mtuanv, ductinhv, haianhphung' });
    this.meta.updateTag({ name: 'keywords', content: 'Meme chúa, Meme hay, Meme hài, Meme bựa, Meme vui, Meme mèo, Meme chó, Meme buồn, Meme giang hồ, Meme Dark, Meme Tiến Bịp, Meme Khá Bảnh, Meme dễ thương, Meme ếch, Meme gấu trúc, Meme cute, Meme Chua' });
    this.meta.updateTag({ name: 'description', content: 'Khám phá và tải xuống những bức ảnh meme hài hước mỗi ngày từ trang chủ của chúng tôi. Duyệt qua bộ sưu tập đa dạng và độc đáo, với công cụ tìm kiếm tiện lợi.' });
    this.meta.updateTag({ property: 'og:title', content: 'Trang không tìm thấy | Meme Chúa' });
    this.meta.updateTag({ property: 'og:description', content: 'Khám phá và tải xuống những bức ảnh meme hài hước mỗi ngày từ trang chủ của chúng tôi. Duyệt qua bộ sưu tập đa dạng và độc đáo, với công cụ tìm kiếm tiện lợi.' });
    this.meta.updateTag({ property: 'og:image', content: 'https://memechua.com/assets/images/meme_logo.png' });
    this.meta.updateTag({ property: 'og:image:secure_url', content: 'https://memechua.com/assets/images/meme_logo.png' });
    this.meta.updateTag({ property: 'og:url', content: 'https://memechua.com/not-found' });
    this.meta.updateTag({ property: 'twitter:title', content: 'Trang không tìm thấy | Meme Chúa' });
    this.meta.updateTag({ property: 'twitter:image', content: 'https://memechua.com/assets/images/meme_logo.png' });
    this.meta.updateTag({ property: 'twitter:description', content: 'Khám phá và tải xuống những bức ảnh meme hài hước mỗi ngày từ trang chủ của chúng tôi. Duyệt qua bộ sưu tập đa dạng và độc đáo, với công cụ tìm kiếm tiện lợi.' });
    this.meta.updateTag({ property: 'twitter:url', content: 'https://memechua.com/not-found' });
  }
}
