<div class="iq-top-navbar">
  <nav class="nav navbar navbar-expand-lg navbar-light iq-navbar p-lg-0">
    <div class="container-fluid navbar-inner">
      <div class="d-flex align-items-center gap-3">
        <a [routerLink]="['/']" class="d-flex align-items-center gap-2 iq-header-logo d-none d-lg-flex">
          <img style="width:50px; height: 50px" alt="Logo Meme Chúa" src="assets/images/meme_logo.png">
          <h3 class="logo-title d-none d-sm-block" data-setting="app_name">Meme Chúa</h3>
        </a>
        <button class="sidebar-toggle" data-toggle="sidebar" data-active="true" style="background-color: transparent; border: none">
          <div class="icon material-symbols-outlined iq-burger-menu">
            menu
          </div>
        </button>
      </div>
      <div class="d-block d-md-none">
        <a [routerLink]="['/']" class="d-flex align-items-center gap-2 iq-header-logo">
          <img style="width:50px; height: 50px" alt="Logo Meme Chúa" src="assets/images/meme_logo.png">
          <h3 class="logo-title d-none d-sm-block" data-setting="app_name">Meme Chúa</h3>
        </a>
      </div>
      <div class="iq-search-bar device-search  position-relative">
        <form class="searchbox" [formGroup]="formData" (ngSubmit)="searchMeme()">
          <a class="search-link d-none d-lg-block" (click)="searchMeme()" role="button">
            <span class="material-symbols-outlined">search</span>
          </a>
          <input type="text" class="text search-input form-control bg-soft-primary  d-none d-lg-block" placeholder="Tìm kiếm..." formControlName="searchText" (keyup.enter)="searchMeme()">
        </form>
      </div>
      <ul class="navbar-nav navbar-list">
        <li class="nav-item dropdown d-none d-lg-block">
          <a [routerLink]="['/tin-tuc']" class="d-flex align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 100 100">
              <path fill="#c9dcf4"
                    d="M78.345,38.553c-1.782-0.196-3.563-0.365-5.345-0.505V23.045c0-3.503-2.586-6.493-6.06-6.942	c-13.711-1.775-33.291-1.803-45.853-0.087C17.605,16.492,15,19.448,15,22.962v54.231c0,3.953,2.898,7.335,6.812,7.884	c18.438,2.586,37.053,2.532,55.833,0.027c0.318,0.002,0.642-0.029,0.968-0.095C82.33,84.255,85,80.988,85,77.196V46.04	C85,42.219,82.142,38.972,78.345,38.553z"></path>
              <circle cx="31.5" cy="31.5" r="5.5" fill="#fff"></circle>
              <circle cx="31.5" cy="31.5" r="2.417" fill="#4a254b"></circle>
              <path fill="#4a254b"
                    d="M44,42.029c2.584,0,4.712-1.948,4.997-4.456C49.031,37.271,48.811,37,48.506,37c-1.733,0-7.275,0-9.011,0 c-0.305,0-0.527,0.271-0.492,0.574C39.288,40.081,41.417,42.029,44,42.029z"></path>
              <circle cx="56.5" cy="31.5" r="5.5" fill="#fff"></circle>
              <circle cx="56.5" cy="31.5" r="2.417" fill="#4a254b"></circle>
              <path fill="#4a254b"
                    d="M78.174,85.07c0,0-0.001,0-0.001,0c-2.845-0.008-5.159-2.331-5.159-5.179v-43.29c0-0.276,0.224-0.5,0.5-0.5 s0.5,0.224,0.5,0.5v43.29c0,2.298,1.867,4.173,4.162,4.179c0.276,0.001,0.5,0.226,0.499,0.501 C78.673,84.848,78.45,85.07,78.174,85.07z"></path>
              <path fill="#6b96d6"
                    d="M46.283,76.84c-0.273,0-0.496-0.22-0.5-0.493C45.779,76.07,46,75.844,46.276,75.84 c7.559-0.103,14.682-0.561,20.6-1.326c0.272-0.038,0.524,0.158,0.56,0.432c0.035,0.274-0.158,0.525-0.432,0.561 c-5.955,0.77-13.118,1.231-20.714,1.334C46.287,76.84,46.285,76.84,46.283,76.84z"></path>
              <path fill="#6b96d6"
                    d="M41.717,76.854c-0.001,0-0.002,0-0.003,0c-7.875-0.051-15.031-0.487-20.695-1.262 c-0.273-0.037-0.465-0.289-0.428-0.562c0.038-0.274,0.29-0.465,0.563-0.428c5.623,0.769,12.734,1.201,20.566,1.252 c0.276,0.002,0.499,0.228,0.497,0.503S41.992,76.854,41.717,76.854z"></path>
              <path fill="#6b96d6"
                    d="M46.283,70.09c-0.273,0-0.496-0.22-0.5-0.493C45.779,69.32,46,69.094,46.276,69.09 c7.559-0.103,14.682-0.561,20.6-1.326c0.272-0.039,0.524,0.158,0.56,0.432c0.035,0.274-0.158,0.525-0.432,0.561 c-5.955,0.77-13.118,1.231-20.714,1.334C46.287,70.09,46.285,70.09,46.283,70.09z"></path>
              <path fill="#6b96d6"
                    d="M41.717,70.104c-0.001,0-0.002,0-0.003,0c-7.875-0.051-15.031-0.487-20.695-1.262 c-0.273-0.037-0.465-0.289-0.428-0.562c0.038-0.274,0.29-0.464,0.563-0.428c5.623,0.769,12.734,1.201,20.566,1.252 c0.276,0.002,0.499,0.228,0.497,0.503S41.992,70.104,41.717,70.104z"></path>
              <path fill="#6b96d6"
                    d="M46.283,63.34c-0.273,0-0.496-0.22-0.5-0.493C45.779,62.57,46,62.344,46.276,62.34 c7.559-0.103,14.682-0.561,20.6-1.326c0.272-0.04,0.524,0.158,0.56,0.432c0.035,0.274-0.158,0.525-0.432,0.561 c-5.955,0.77-13.118,1.231-20.714,1.334C46.287,63.34,46.285,63.34,46.283,63.34z"></path>
              <path fill="#6b96d6"
                    d="M41.717,63.354c-0.001,0-0.002,0-0.003,0c-7.875-0.051-15.031-0.487-20.695-1.262 c-0.273-0.037-0.465-0.289-0.428-0.562c0.038-0.273,0.29-0.463,0.563-0.428c5.623,0.769,12.734,1.201,20.566,1.252 c0.276,0.002,0.499,0.228,0.497,0.503S41.992,63.354,41.717,63.354z"></path>
              <g>
                <path fill="#6b96d6"
                      d="M46.283,56.59c-0.273,0-0.496-0.22-0.5-0.493C45.779,55.82,46,55.594,46.276,55.59 c7.559-0.103,14.682-0.561,20.6-1.326c0.272-0.042,0.524,0.158,0.56,0.432c0.035,0.274-0.158,0.525-0.432,0.561 c-5.955,0.77-13.118,1.231-20.714,1.334C46.287,56.59,46.285,56.59,46.283,56.59z"></path>
              </g>
              <g>
                <path fill="#6b96d6"
                      d="M41.717,56.604c-0.001,0-0.002,0-0.003,0c-7.875-0.051-15.031-0.487-20.695-1.262 c-0.273-0.037-0.465-0.289-0.428-0.562c0.038-0.273,0.29-0.462,0.563-0.428c5.623,0.769,12.734,1.201,20.566,1.252 c0.276,0.002,0.499,0.228,0.497,0.503S41.992,56.604,41.717,56.604z"></path>
              </g>
              <g>
                <path fill="#6b96d6"
                      d="M46.283,49.84c-0.273,0-0.496-0.22-0.5-0.493C45.779,49.07,46,48.844,46.276,48.84 c7.559-0.103,14.682-0.561,20.6-1.326c0.272-0.037,0.524,0.158,0.56,0.432c0.035,0.274-0.158,0.525-0.432,0.561 c-5.955,0.77-13.118,1.231-20.714,1.334C46.287,49.84,46.285,49.84,46.283,49.84z"></path>
              </g>
              <g>
                <path fill="#6b96d6"
                      d="M41.717,49.854c-0.001,0-0.002,0-0.003,0c-7.875-0.051-15.031-0.487-20.695-1.262 c-0.273-0.037-0.465-0.289-0.428-0.562c0.038-0.274,0.29-0.461,0.563-0.428c5.623,0.769,12.734,1.201,20.566,1.252 c0.276,0.002,0.499,0.228,0.497,0.503S41.992,49.854,41.717,49.854z"></path>
              </g>
            </svg>
          </a>
        </li>
        <li class="nav-item dropdown d-none d-lg-block">
          <a href="https://www.facebook.com/memechuavn" aria-label="Facebook Meme Chúa" target="_blank" class="d-flex align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
              <path fill="#039be5" d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"></path>
              <path fill="#fff"
                    d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z">
              </path>
            </svg>
          </a>
        </li>
        <li class="nav-item dropdown d-none d-lg-block">
          <a href="https://twitter.com/MemeChuaVN" aria-label="Twitter Meme Chúa" target="_blank" class="d-flex align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 30 30">
              <path
                d="M 6 4 C 4.895 4 4 4.895 4 6 L 4 24 C 4 25.105 4.895 26 6 26 L 24 26 C 25.105 26 26 25.105 26 24 L 26 6 C 26 4.895 25.105 4 24 4 L 6 4 z M 8.6484375 9 L 13.259766 9 L 15.951172 12.847656 L 19.28125 9 L 20.732422 9 L 16.603516 13.78125 L 21.654297 21 L 17.042969 21 L 14.056641 16.730469 L 10.369141 21 L 8.8945312 21 L 13.400391 15.794922 L 8.6484375 9 z M 10.878906 10.183594 L 17.632812 19.810547 L 19.421875 19.810547 L 12.666016 10.183594 L 10.878906 10.183594 z">
              </path>
            </svg>
          </a>
        </li>
        <li class="nav-item dropdown d-none d-lg-block">
          <a href="https://www.youtube.com/@MemeChuaChamCom" aria-label="Youtube Meme Chúa" target="_blank" class="d-flex align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
              <path fill="#FF3D00"
                    d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z">
              </path>
              <path fill="#FFF" d="M20 31L20 17 32 24z"></path>
            </svg>
          </a>
        </li>
        <li class="nav-item dropdown d-none d-lg-block">
          <a href="mailto:memechua.contact@gmail.com" aria-label="Gửi mail tới Meme Chúa" target="_blank" class="d-flex align-items-center">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
              <path fill="#4caf50" d="M45,16.2l-5,2.75l-5,4.75L35,40h7c1.657,0,3-1.343,3-3V16.2z"></path>
              <path fill="#1e88e5" d="M3,16.2l3.614,1.71L13,23.7V40H6c-1.657,0-3-1.343-3-3V16.2z"></path>
              <polygon fill="#e53935" points="35,11.2 24,19.45 13,11.2 12,17 13,23.7 24,31.95 35,23.7 36,17"></polygon>
              <path fill="#c62828"
                    d="M3,12.298V16.2l10,7.5V11.2L9.876,8.859C9.132,8.301,8.228,8,7.298,8h0C4.924,8,3,9.924,3,12.298z">
              </path>
              <path fill="#fbc02d"
                    d="M45,12.298V16.2l-10,7.5V11.2l3.124-2.341C38.868,8.301,39.772,8,40.702,8h0 C43.076,8,45,9.924,45,12.298z">
              </path>
            </svg>
          </a>
        </li>
      </ul>
      <div class=" border-top top-header-responsive d-lg-none">
        <ul class="list-unstyled p-0 m-0 menu-list">
          <li class="icon-class">
            <a [routerLink]="['/tin-tuc']" class="d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 100 100">
                <path fill="#c9dcf4"
                      d="M78.345,38.553c-1.782-0.196-3.563-0.365-5.345-0.505V23.045c0-3.503-2.586-6.493-6.06-6.942	c-13.711-1.775-33.291-1.803-45.853-0.087C17.605,16.492,15,19.448,15,22.962v54.231c0,3.953,2.898,7.335,6.812,7.884	c18.438,2.586,37.053,2.532,55.833,0.027c0.318,0.002,0.642-0.029,0.968-0.095C82.33,84.255,85,80.988,85,77.196V46.04	C85,42.219,82.142,38.972,78.345,38.553z"></path>
                <circle cx="31.5" cy="31.5" r="5.5" fill="#fff"></circle>
                <circle cx="31.5" cy="31.5" r="2.417" fill="#4a254b"></circle>
                <path fill="#4a254b"
                      d="M44,42.029c2.584,0,4.712-1.948,4.997-4.456C49.031,37.271,48.811,37,48.506,37c-1.733,0-7.275,0-9.011,0 c-0.305,0-0.527,0.271-0.492,0.574C39.288,40.081,41.417,42.029,44,42.029z"></path>
                <circle cx="56.5" cy="31.5" r="5.5" fill="#fff"></circle>
                <circle cx="56.5" cy="31.5" r="2.417" fill="#4a254b"></circle>
                <path fill="#4a254b"
                      d="M78.174,85.07c0,0-0.001,0-0.001,0c-2.845-0.008-5.159-2.331-5.159-5.179v-43.29c0-0.276,0.224-0.5,0.5-0.5 s0.5,0.224,0.5,0.5v43.29c0,2.298,1.867,4.173,4.162,4.179c0.276,0.001,0.5,0.226,0.499,0.501 C78.673,84.848,78.45,85.07,78.174,85.07z"></path>
                <path fill="#6b96d6"
                      d="M46.283,76.84c-0.273,0-0.496-0.22-0.5-0.493C45.779,76.07,46,75.844,46.276,75.84 c7.559-0.103,14.682-0.561,20.6-1.326c0.272-0.038,0.524,0.158,0.56,0.432c0.035,0.274-0.158,0.525-0.432,0.561 c-5.955,0.77-13.118,1.231-20.714,1.334C46.287,76.84,46.285,76.84,46.283,76.84z"></path>
                <path fill="#6b96d6"
                      d="M41.717,76.854c-0.001,0-0.002,0-0.003,0c-7.875-0.051-15.031-0.487-20.695-1.262 c-0.273-0.037-0.465-0.289-0.428-0.562c0.038-0.274,0.29-0.465,0.563-0.428c5.623,0.769,12.734,1.201,20.566,1.252 c0.276,0.002,0.499,0.228,0.497,0.503S41.992,76.854,41.717,76.854z"></path>
                <path fill="#6b96d6"
                      d="M46.283,70.09c-0.273,0-0.496-0.22-0.5-0.493C45.779,69.32,46,69.094,46.276,69.09 c7.559-0.103,14.682-0.561,20.6-1.326c0.272-0.039,0.524,0.158,0.56,0.432c0.035,0.274-0.158,0.525-0.432,0.561 c-5.955,0.77-13.118,1.231-20.714,1.334C46.287,70.09,46.285,70.09,46.283,70.09z"></path>
                <path fill="#6b96d6"
                      d="M41.717,70.104c-0.001,0-0.002,0-0.003,0c-7.875-0.051-15.031-0.487-20.695-1.262 c-0.273-0.037-0.465-0.289-0.428-0.562c0.038-0.274,0.29-0.464,0.563-0.428c5.623,0.769,12.734,1.201,20.566,1.252 c0.276,0.002,0.499,0.228,0.497,0.503S41.992,70.104,41.717,70.104z"></path>
                <path fill="#6b96d6"
                      d="M46.283,63.34c-0.273,0-0.496-0.22-0.5-0.493C45.779,62.57,46,62.344,46.276,62.34 c7.559-0.103,14.682-0.561,20.6-1.326c0.272-0.04,0.524,0.158,0.56,0.432c0.035,0.274-0.158,0.525-0.432,0.561 c-5.955,0.77-13.118,1.231-20.714,1.334C46.287,63.34,46.285,63.34,46.283,63.34z"></path>
                <path fill="#6b96d6"
                      d="M41.717,63.354c-0.001,0-0.002,0-0.003,0c-7.875-0.051-15.031-0.487-20.695-1.262 c-0.273-0.037-0.465-0.289-0.428-0.562c0.038-0.273,0.29-0.463,0.563-0.428c5.623,0.769,12.734,1.201,20.566,1.252 c0.276,0.002,0.499,0.228,0.497,0.503S41.992,63.354,41.717,63.354z"></path>
                <g>
                  <path fill="#6b96d6"
                        d="M46.283,56.59c-0.273,0-0.496-0.22-0.5-0.493C45.779,55.82,46,55.594,46.276,55.59 c7.559-0.103,14.682-0.561,20.6-1.326c0.272-0.042,0.524,0.158,0.56,0.432c0.035,0.274-0.158,0.525-0.432,0.561 c-5.955,0.77-13.118,1.231-20.714,1.334C46.287,56.59,46.285,56.59,46.283,56.59z"></path>
                </g>
                <g>
                  <path fill="#6b96d6"
                        d="M41.717,56.604c-0.001,0-0.002,0-0.003,0c-7.875-0.051-15.031-0.487-20.695-1.262 c-0.273-0.037-0.465-0.289-0.428-0.562c0.038-0.273,0.29-0.462,0.563-0.428c5.623,0.769,12.734,1.201,20.566,1.252 c0.276,0.002,0.499,0.228,0.497,0.503S41.992,56.604,41.717,56.604z"></path>
                </g>
                <g>
                  <path fill="#6b96d6"
                        d="M46.283,49.84c-0.273,0-0.496-0.22-0.5-0.493C45.779,49.07,46,48.844,46.276,48.84 c7.559-0.103,14.682-0.561,20.6-1.326c0.272-0.037,0.524,0.158,0.56,0.432c0.035,0.274-0.158,0.525-0.432,0.561 c-5.955,0.77-13.118,1.231-20.714,1.334C46.287,49.84,46.285,49.84,46.283,49.84z"></path>
                </g>
                <g>
                  <path fill="#6b96d6"
                        d="M41.717,49.854c-0.001,0-0.002,0-0.003,0c-7.875-0.051-15.031-0.487-20.695-1.262 c-0.273-0.037-0.465-0.289-0.428-0.562c0.038-0.274,0.29-0.461,0.563-0.428c5.623,0.769,12.734,1.201,20.566,1.252 c0.276,0.002,0.499,0.228,0.497,0.503S41.992,49.854,41.717,49.854z"></path>
                </g>
              </svg>
            </a>
          </li>
          <li class="icon-class">
            <a href="https://www.facebook.com/memechuavn" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
                <path fill="#039be5" d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"></path>
                <path fill="#fff"
                      d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z">
                </path>
              </svg>
            </a>
          </li>
          <li class="icon-class">
            <a href="https://twitter.com/MemeChuaVN" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 30 30">
                <path
                  d="M 6 4 C 4.895 4 4 4.895 4 6 L 4 24 C 4 25.105 4.895 26 6 26 L 24 26 C 25.105 26 26 25.105 26 24 L 26 6 C 26 4.895 25.105 4 24 4 L 6 4 z M 8.6484375 9 L 13.259766 9 L 15.951172 12.847656 L 19.28125 9 L 20.732422 9 L 16.603516 13.78125 L 21.654297 21 L 17.042969 21 L 14.056641 16.730469 L 10.369141 21 L 8.8945312 21 L 13.400391 15.794922 L 8.6484375 9 z M 10.878906 10.183594 L 17.632812 19.810547 L 19.421875 19.810547 L 12.666016 10.183594 L 10.878906 10.183594 z">
                </path>
              </svg>
            </a>
          </li>
          <li class="icon-class">
            <a href="https://www.youtube.com/@MemeChuaChamCom" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
                <path fill="#FF3D00"
                      d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z">
                </path>
                <path fill="#FFF" d="M20 31L20 17 32 24z"></path>
              </svg>
            </a>
          </li>
          <li class="icon-class">
            <a href="mailto:memechua.contact@gmail.com" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
                <path fill="#4caf50" d="M45,16.2l-5,2.75l-5,4.75L35,40h7c1.657,0,3-1.343,3-3V16.2z"></path>
                <path fill="#1e88e5" d="M3,16.2l3.614,1.71L13,23.7V40H6c-1.657,0-3-1.343-3-3V16.2z"></path>
                <polygon fill="#e53935"
                         points="35,11.2 24,19.45 13,11.2 12,17 13,23.7 24,31.95 35,23.7 36,17"></polygon>
                <path fill="#c62828"
                      d="M3,12.298V16.2l10,7.5V11.2L9.876,8.859C9.132,8.301,8.228,8,7.298,8h0C4.924,8,3,9.924,3,12.298z">
                </path>
                <path fill="#fbc02d"
                      d="M45,12.298V16.2l-10,7.5V11.2l3.124-2.341C38.868,8.301,39.772,8,40.702,8h0 C43.076,8,45,9.924,45,12.298z">
                </path>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
<div [class.flex-container]="!isMobile()" class="w-100">
  <aside [style.position]="isMobile() ? '' : 'static'" class="sidebar sidebar-default sidebar-base navs-rounded-all" id="first-tour" data-toggle="main-sidebar"
         data-sidebar="responsive">
    <div class="sidebar-body pt-0 data-scrollbar">
      <div class="sidebar-list">
        <ul class="navbar-nav iq-main-menu" id="sidebar-menu">
          <li class="nav-item">
            <div class="d-block d-md-none mt-3">
              <div class="d-flex align-items-center justify-content-between d-lg-none w-100">
                <div class="iq-search-bar device-search position-relative" style="padding: 0">
                  <form class="searchbox w-100" [formGroup]="formData" (ngSubmit)="searchMeme()">
                    <a class="search-link" (click)="searchMeme()" role="button">
                      <span class="material-symbols-outlined">search</span>
                    </a>
                    <input type="text" class="text search-input form-control bg-soft-primary"
                           placeholder="Tìm kiếm..."
                           formControlName="searchText" (keyup.enter)="searchMeme()">
                  </form>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item static-item">
            <a class="nav-link static-item disabled" href="#" tabindex="-1">
              <span class="default-icon">Tag Hot</span>
              <span class="mini-icon" data-bs-toggle="tooltip" title="Social" data-bs-placement="right">-</span>
            </a>
          </li>
          <li class="nav-item" *ngFor="let item of listTagHot">
            <a class="nav-link" aria-current="page" [routerLink]="['/tag/' + item.slug]" routerLinkActive="active" (click)="closeSidebar()">
              <i class="icon material-symbols-outlined">
                bolt
              </i>
              <span class="item-name">{{ item.name }}</span>
            </a>
          </li>
          <li class="nav-item static-item">
            <a class="nav-link static-item disabled" href="#" tabindex="-1">
              <span class="default-icon">Tag Phổ Biến</span>
              <span class="mini-icon" data-bs-toggle="tooltip" title="Social" data-bs-placement="right">-</span>
            </a>
          </li>
          <li class="nav-item" *ngFor="let item of listTag">
            <a class="nav-link" aria-current="page" [routerLink]="['/tag/' + item.slug]" (click)="closeSidebar()">
              <i class="icon material-symbols-outlined">
                tag
              </i>
              <span class="item-name">{{ item.name }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="sidebar-footer"></div>
  </aside>
  <div>
    <div id="content-page" class="content-page">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-10">
            <list-shopee *ngIf="isMobile()"></list-shopee>
            <router-outlet></router-outlet>
          </div>
          <div class="col-lg-2" *ngIf="!isMobile()">
<!--            <div class="position-fixed">-->
              <list-shopee></list-shopee>
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<footer class="iq-footer bg-white">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6">
        <ul class="list-inline mb-0">
          <li class="list-inline-item"><a [routerLink]="['/terms-privacy']">Điều khoản sử dụng</a></li>
          <li class="list-inline-item"><a [routerLink]="['/contact']">Liên hệ</a></li>
        </ul>
      </div>
      <div class="col-lg-6 d-flex justify-content-end">
        Copyright © Meme Chúa 2024
      </div>
    </div>
  </div>
</footer>
<ngx-spinner template="<img src='/assets/images/loading.gif'/>"></ngx-spinner>
