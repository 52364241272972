// fb-comments.directive.ts
import {Directive, ElementRef, Inject, Input, OnChanges, PLATFORM_ID, SimpleChanges} from '@angular/core';
import {isPlatformBrowser} from "@angular/common";

@Directive({
  selector: '[appFbComments]'
})
export class FbCommentsDirective implements OnChanges {
  @Input() url: string = '';
  @Input() width: string = '100%';
  @Input() numPosts: number = 5;

  constructor(
    private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['url']) {
      this.renderCommentsPlugin();
    }
  }

  renderCommentsPlugin(): void {
    // Ensure FB SDK is loaded
    if (isPlatformBrowser(this.platformId)) {
      if ((window as any).FB) {
        this.el.nativeElement.innerHTML = `<div class="fb-comments"
        data-href="${this.url}"
        data-width="${this.width}"
        data-numposts="${this.numPosts}">
      </div>`;

        (window as any).FB.XFBML.parse(this.el.nativeElement);
      }
    }
  }
}
