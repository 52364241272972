import {HttpClient} from '@angular/common/http';
import {environment} from "../../environments/environment";
import {ResponseData} from "../models/response-data";

export abstract class BaseService {
  apiUrl = environment.apiUrl;
  main = 'main';

  protected constructor(protected httpClient: HttpClient, private controller: string) {
  }

  detailBySlug(slug: string) {
    const url = `${this.apiUrl}/api/${this.main}/${this.controller}/detail-by-slug/${slug}`;
    return this.httpClient.get<ResponseData>(url).toPromise();
  }

  searchPage(body: any) {
    const url = `${this.apiUrl}/api/${this.main}/${this.controller}/search-page`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }

  searchList(body: any) {
    const url = `${this.apiUrl}/api/${this.main}/${this.controller}/search-list`;
    return this.httpClient.post<ResponseData>(url, body).toPromise();
  }
}
