import {Component, Injector, OnInit} from '@angular/core';
import {SeoService} from "../../services/seo.service";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  constructor(
    private seo: SeoService
  ) {
  }

  ngOnInit()  {
    this.seo.setMetaContact();
  }
}
