import {Component, Input} from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'download-meme',
  templateUrl: './download-meme.component.html',
  styleUrls: ['./download-meme.component.css']
})
export class DownloadMemeComponent{
  @Input() meme: any;
  apiUrl = environment.apiUrl;

  constructor(
  ) {
  }

  downloadMeme(e : any) {
    e.preventDefault();
    if (typeof window !== "undefined") {
      window.open('https://shope.ee/8zgaz3zciP', "_blank");
      window.open(`${this.apiUrl}/api/main/memes/download/${this.meme?.image.replace('upload/memes/', '')}`, "_self");
    }
  }
}
