import {Component, Injector, Input} from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'list-news-related',
  templateUrl: './list-news-related.component.html',
  styleUrls: ['./list-news-related.component.css']
})
export class ListNewsRelatedComponent{
  @Input() listNewsRelated: any = [];
  @Input() title = 'Tin tức liên quan';
  apiUrl: string = environment.apiUrl;

  constructor(
  ) {
  }
}
