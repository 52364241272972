<div class="row">
    <div class="col-lg-12">
       <div class="card">
          <div class="card-header d-flex justify-content-between">
             <div class="header-title">
                <h4 class="card-title fw-bold">Điều khoản & Quyền riêng tư</h4>
             </div>
          </div>
          <div class="card-body">
            <p>Nhà cung cấp (“chúng tôi”, “của chúng tôi”, “Meme Chúa”, “memechua.com”) của các dịch vụ được cung cấp bởi trang web này sẽ không chịu bất kỳ trách nhiệm nào đối với bất kỳ nội dung nào tải lên.</p>
            <p>Tất cả nội dung là nhằm mục đích giải trí. Kết quả có thể đúng hoặc không chính xác đối với bạn. Bạn không nên khó chịu với kết quả vì bất kỳ lý do gì.</p>
            <p>Bạn phải trên 13 tuổi để sử dụng dịch vụ.</p>
            <p>Bằng cách xem bất cứ nội dung nào trên trang web của chúng tôi, điều đó được coi là bạn đồng ý với Điều khoản và Chính sách quyền riêng tư của chúng tôi.</p>
            <p>Các điều khoản này có thể thay đổi bất kỳ lúc nào mà không cần báo trước.</p>
          </div>
       </div>
    </div>
 </div>
