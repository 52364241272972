import {Component, Input} from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'list-meme-related',
  templateUrl: './list-meme-related.component.html',
  styleUrls: ['./list-meme-related.component.css']
})
export class ListMemeRelatedComponent{
  @Input() listMemeRelated: any = [];
  @Input() title = 'Meme liên quan';
  apiUrl: string = environment.apiUrl;

  constructor(
  ) {
  }
}
