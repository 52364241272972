import {AfterViewInit, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {NewsService} from "../../../services/news.service";
import {SeoService} from "../../../services/seo.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-meme',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.css']
})
export class NewsDetailComponent implements OnInit, AfterViewInit {
  currentUrl: string = environment.mainUrl;
  apiUrl: string = environment.apiUrl;
  newsDetail: any;
  listNewsRelated: any = [];

  constructor(
    private newsService: NewsService,
    private seo: SeoService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
  }

  async ngOnInit() {
    this.route.paramMap.subscribe(async params => {
      await this.spinner.show();
      const slug = params.get('slug');
      if (slug) {
        const data = await this.detailBySlug(slug);
        if (data) {
          this.newsDetail = data;
          this.seo.setMetaNewsDetail(this.newsDetail?.title, this.newsDetail?.slug, this.apiUrl + '/' + this.newsDetail?.image);
          this.searchPageNewsRelated();
        } else {
          await this.router.navigate(['/not-found']);
        }
      }
      await this.spinner.hide();
    });
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.currentUrl = window.location.href;
    } else {
      this.currentUrl = environment.mainUrl;
    }
  }

  searchPageNewsRelated() {
    const request = {
      page_index: 1,
      page_size: 10,
      search_text: '',
      tag_id: 0,
      is_hot: false,
      tag_ids: this.newsDetail?.tags?.map((i: any) => i.id).toString()
    };
    this.newsService.searchPage(request).then((res) => {
      if (res?.status) {
        this.listNewsRelated = res.data.data;
        this.listNewsRelated = this.listNewsRelated.filter((i: any) => i.id != this.newsDetail?.id);
      }
    });
  }

  async detailBySlug(slug: string) {
    if (slug) {
      let res = await this.newsService.detailBySlug(slug);
      if(res && res?.status){
        return res.data;
      } else {
        return null;
      }
    }
  }
}
