import {Component, OnInit} from '@angular/core';
import { MemeService } from '../../services/meme.service';
import {PAGE_SIZE_DEFAULT} from "../../constants/config";
import {SeoService} from "../../services/seo.service";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  pageIndex: number = 1;
  pageSize: number = PAGE_SIZE_DEFAULT;
  totalRecord: number = 0;
  listMeme: any = [];
  listMemeHot: any = [];

  constructor(
    private memeService: MemeService,
    private seo: SeoService,
    private spinner: NgxSpinnerService
  ) {
  }

  async ngOnInit() {
    this.seo.setMetaHome();
    this.resetData();
    await this.searchPage(true);
    await this.searchPageMemeHot();
  }

  resetData() {
    this.listMeme = [];
    this.listMemeHot = [];
    this.pageIndex = 1;
    this.pageSize = PAGE_SIZE_DEFAULT;
    this.totalRecord = 0;
  }

  async searchPage(isInit: boolean) {
    if(isInit || this.listMeme.length < this.totalRecord){
      const request = {
        page_index: this.pageIndex,
        page_size: this.pageSize,
        search_text: '',
        tag_id: 0,
        is_hot: false
      };
      await this.spinner.show();
      await this.memeService.searchPage(request).then(async (res) => {
        if (res?.status) {
          const data = res.data;
          this.listMeme.push(...data.data);
          this.totalRecord = data.total;
          this.pageIndex++;
        }
      });
      await this.spinner.hide();
    }
  }

  async searchPageMemeHot() {
    const request = {
      page_index: 1,
      page_size: 10,
      search_text: '',
      tag_id: 0,
      is_hot: true
    };
    await this.memeService.searchPage(request).then((res) => {
      if (res?.status) {
        this.listMemeHot = res.data.data;
      }
    });
  }
}
