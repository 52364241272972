<div class="row">
	<div class="col-lg-9">
    <div class="card card-block card-stretch blog blog-detail">
      <div class="card-body">
        <div class="image-block">
          <div class="image-container w-50" [ngClass]="isMobile() ? 'w-100' : 'w-50'">
            <img *ngIf="memeDetail" [src]="apiUrl + '/' + memeDetail?.image"
                 alt="{{memeDetail?.content}}" class="img-fluid rounded w-100">
            <div class="overlay"></div>
          </div>
        </div>
        <div class="blog-description mt-3">
          <h3 class="fw-bold">Meme {{memeDetail?.name}}</h3>
          <div class="mb-2">
            <a href="https://www.facebook.com/sharer/sharer.php?u={{currentUrl}}&amp;src=sdkpreparse"
               class="me-1" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30"
                   viewBox="0 0 48 48">
                <path fill="#039be5" d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"></path>
                <path fill="#fff"
                      d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z">
                </path>
              </svg>
            </a>
            <a href="http://www.facebook.com/dialog/send?app_id=405560652162387&link={{currentUrl}}&redirect_uri={{currentUrl}}"
               class="me-1" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30"
                   viewBox="0 0 48 48">
                <radialGradient id="8O3wK6b5ASW2Wn6hRCB5xa_YFbzdUk7Q3F8_gr1" cx="11.087" cy="7.022"
                                r="47.612" gradientTransform="matrix(1 0 0 -1 0 50)"
                                gradientUnits="userSpaceOnUse">
                  <stop offset="0" stop-color="#1292ff"></stop>
                  <stop offset=".079" stop-color="#2982ff"></stop>
                  <stop offset=".23" stop-color="#4e69ff"></stop>
                  <stop offset=".351" stop-color="#6559ff"></stop>
                  <stop offset=".428" stop-color="#6d53ff"></stop>
                  <stop offset=".754" stop-color="#df47aa"></stop>
                  <stop offset=".946" stop-color="#ff6257"></stop>
                </radialGradient>
                <path fill="url(#8O3wK6b5ASW2Wn6hRCB5xa_YFbzdUk7Q3F8_gr1)"
                      d="M44,23.5C44,34.27,35.05,43,24,43c-1.651,0-3.25-0.194-4.784-0.564	c-0.465-0.112-0.951-0.069-1.379,0.145L13.46,44.77C12.33,45.335,11,44.513,11,43.249v-4.025c0-0.575-0.257-1.111-0.681-1.499	C6.425,34.165,4,29.11,4,23.5C4,12.73,12.95,4,24,4S44,12.73,44,23.5z">
                </path>
                <path
                  d="M34.992,17.292c-0.428,0-0.843,0.142-1.2,0.411l-5.694,4.215	c-0.133,0.1-0.28,0.15-0.435,0.15c-0.15,0-0.291-0.047-0.41-0.136l-3.972-2.99c-0.808-0.601-1.76-0.918-2.757-0.918	c-1.576,0-3.025,0.791-3.876,2.116l-1.211,1.891l-4.12,6.695c-0.392,0.614-0.422,1.372-0.071,2.014	c0.358,0.654,1.034,1.06,1.764,1.06c0.428,0,0.843-0.142,1.2-0.411l5.694-4.215c0.133-0.1,0.28-0.15,0.435-0.15	c0.15,0,0.291,0.047,0.41,0.136l3.972,2.99c0.809,0.602,1.76,0.918,2.757,0.918c1.576,0,3.025-0.791,3.876-2.116l1.211-1.891	l4.12-6.695c0.392-0.614,0.422-1.372,0.071-2.014C36.398,17.698,35.722,17.292,34.992,17.292L34.992,17.292z"
                  opacity=".05"></path>
                <path
                  d="M34.992,17.792c-0.319,0-0.63,0.107-0.899,0.31l-5.697,4.218	c-0.216,0.163-0.468,0.248-0.732,0.248c-0.259,0-0.504-0.082-0.71-0.236l-3.973-2.991c-0.719-0.535-1.568-0.817-2.457-0.817	c-1.405,0-2.696,0.705-3.455,1.887l-1.21,1.891l-4.115,6.688c-0.297,0.465-0.32,1.033-0.058,1.511c0.266,0.486,0.787,0.8,1.325,0.8	c0.319,0,0.63-0.107,0.899-0.31l5.697-4.218c0.216-0.163,0.468-0.248,0.732-0.248c0.259,0,0.504,0.082,0.71,0.236l3.973,2.991	c0.719,0.535,1.568,0.817,2.457,0.817c1.405,0,2.696-0.705,3.455-1.887l1.21-1.891l4.115-6.688c0.297-0.465,0.32-1.033,0.058-1.511	C36.051,18.106,35.531,17.792,34.992,17.792L34.992,17.792z"
                  opacity=".07"></path>
                <path fill="#fff"
                      d="M34.394,18.501l-5.7,4.22c-0.61,0.46-1.44,0.46-2.04,0.01L22.68,19.74	c-1.68-1.25-4.06-0.82-5.19,0.94l-1.21,1.89l-4.11,6.68c-0.6,0.94,0.55,2.01,1.44,1.34l5.7-4.22c0.61-0.46,1.44-0.46,2.04-0.01	l3.974,2.991c1.68,1.25,4.06,0.82,5.19-0.94l1.21-1.89l4.11-6.68C36.434,18.901,35.284,17.831,34.394,18.501z">
                </path>
              </svg>
            </a>
            <a href="http://twitter.com/share?text=Meme {{memeDetail?.name}}&url={{currentUrl}}&hashtags=meme,memechua,memechuavn"
               class="me-1" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30"
                   viewBox="0 0 30 30">
                <path
                  d="M 6 4 C 4.895 4 4 4.895 4 6 L 4 24 C 4 25.105 4.895 26 6 26 L 24 26 C 25.105 26 26 25.105 26 24 L 26 6 C 26 4.895 25.105 4 24 4 L 6 4 z M 8.6484375 9 L 13.259766 9 L 15.951172 12.847656 L 19.28125 9 L 20.732422 9 L 16.603516 13.78125 L 21.654297 21 L 17.042969 21 L 14.056641 16.730469 L 10.369141 21 L 8.8945312 21 L 13.400391 15.794922 L 8.6484375 9 z M 10.878906 10.183594 L 17.632812 19.810547 L 19.421875 19.810547 L 12.666016 10.183594 L 10.878906 10.183594 z">
                </path>
              </svg>
            </a>
            <a href="https://pinterest.com/pin/create/button/?url={{currentUrl}}&media={{apiUrl}}/{{memeDetail?.image}}&description=Meme {{memeDetail?.name}}"
               class="me-1" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30"
                   viewBox="0 0 48 48">
                <circle cx="24" cy="24" r="20" fill="#E60023"></circle>
                <path fill="#FFF"
                      d="M24.4439087,11.4161377c-8.6323242,0-13.2153931,5.7946167-13.2153931,12.1030884	c0,2.9338379,1.5615234,6.5853882,4.0599976,7.7484131c0.378418,0.1762085,0.581543,0.1000366,0.668457-0.2669067	c0.0668945-0.2784424,0.4038086-1.6369019,0.5553589-2.2684326c0.0484619-0.2015381,0.0246582-0.3746338-0.1384277-0.5731201	c-0.8269653-1.0030518-1.4884644-2.8461304-1.4884644-4.5645752c0-4.4115601,3.3399658-8.6799927,9.0299683-8.6799927	c4.9130859,0,8.3530884,3.3484497,8.3530884,8.1369019c0,5.4099731-2.7322998,9.1584473-6.2869263,9.1584473	c-1.9630737,0-3.4330444-1.6238403-2.9615479-3.6153564c0.5654297-2.3769531,1.6569214-4.9415283,1.6569214-6.6584473	c0-1.5354004-0.8230591-2.8169556-2.5299683-2.8169556c-2.006958,0-3.6184692,2.0753784-3.6184692,4.8569336	c0,1.7700195,0.5984497,2.9684448,0.5984497,2.9684448s-1.9822998,8.3815308-2.3453979,9.9415283	c-0.4019775,1.72229-0.2453003,4.1416016-0.0713501,5.7233887l0,0c0.4511108,0.1768799,0.9024048,0.3537598,1.3687744,0.4981079l0,0	c0.8168945-1.3278198,2.0349731-3.5056763,2.4864502-5.2422485c0.2438354-0.9361572,1.2468872-4.7546387,1.2468872-4.7546387	c0.6515503,1.2438965,2.5561523,2.296936,4.5831299,2.296936c6.0314941,0,10.378418-5.546936,10.378418-12.4400024	C36.7738647,16.3591919,31.3823242,11.4161377,24.4439087,11.4161377z">
                </path>
              </svg>
            </a>
          </div>
          <div class="mb-3 pb-3 border-bottom">
							<span *ngFor="let tag of memeDetail?.tags">
								<a class="btn bg-soft-primary btn-sm me-1 mb-1"
                   [routerLink]="['/tag/' + tag.slug]">#{{tag.name}}</a>
							</span>
          </div>
          <div class="blog-meta d-flex align-items-center mb-3 position-right-side flex-wrap">
            <!-- <div class="date me-4 d-flex align-items-center"><i
                class="material-symbols-outlined pe-2 md-18 text-primary">calendar_month</i>2 Weeks
              ago</div>
            <div class="like me-4 d-flex align-items-center"><i
                class="material-symbols-outlined pe-2 md-18 text-primary">
                thumb_up_alt
              </i>20 like
            </div>
            <div class="comments me-4 d-flex align-items-center"><i
                class="material-symbols-outlined pe-2 md-18 text-primary">
                mode_comment
              </i>82 comments
            </div> -->
            <download-meme [meme]="memeDetail"></download-meme>
          </div>
          <!-- <p>Voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p> -->
        </div>
      </div>
    </div>
    <div class="card card-block card-stretch blog user-comment">
      <div class="card-header d-flex justify-content-between">
        <div class="header-title">
          <h4 class="card-title">Bình luận</h4>
        </div>
      </div>
      <div class="card-body">
        <div appFbComments [url]="currentUrl" [width]="'100%'" [numPosts]="10"></div>
      </div>
    </div>
	</div>
	<div class="col-lg-3">
		<list-meme-related [listMemeRelated]="listMemeRelated"></list-meme-related>
	</div>
</div>
