<div class="row no-gutters height-self-center">
   <div class="col-lg-12 text-center align-self-center">
     <div class="iq-error position-relative mt-5">
       <img src="../assets/images/error/404.png" class="img-fluid iq-error-img" alt="">
       <h2 class="mb-0 text-center">Trang Không Tìm Thấy</h2>
       <p class="text-center">Xin lỗi, trang bạn đang tìm kiếm không tồn tại.</p>
       <a class="btn btn-primary mt-3" [routerLink]="['/']">
         <span class="d-flex align-items-center">
           <i class="material-symbols-outlined md-18 me-1">
             home
           </i>Về trang chủ
         </span></a>
     </div>
   </div>
 </div>
