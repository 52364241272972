import {Component, Injector, OnInit} from '@angular/core';
import { MemeService } from '../../services/meme.service';
import { TagService } from 'src/app/services/tag.service';
import {PAGE_SIZE_DEFAULT} from "../../constants/config";
import {NewsService} from "../../services/news.service";
import {SeoService} from "../../services/seo.service";
import {ActivatedRoute} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  searchText: any;
  memePageIndex: number = 1;
  memePageSize: number = PAGE_SIZE_DEFAULT;
  memeTotalRecord: number = 0;
  newsPageIndex: number = 1;
  newsPageSize: number = PAGE_SIZE_DEFAULT;
  newsTotalRecord: number = 0;
  listTag: any = [];
  listNews: any = [];
  listMeme: any = [];
  listMemeHot: any = [];

  constructor(
    private memeService: MemeService,
    private tagService: TagService,
    private newsService: NewsService,
    private seo: SeoService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
  ) {
  }

  async ngOnInit() {
    this.route.paramMap.subscribe(async params => {
      this.searchText = params.get('searchText');
      this.seo.setMetaSearch(this.searchText);
      this.resetData();
      await this.searchPageMeme(true);
      await this.searchPageNews(true);
      await this.searchListTag();
    });
  }

  resetData() {
    this.listTag = [];
    this.listNews = [];
    this.listMeme = [];
    this.listMemeHot = [];
    this.memePageIndex = 1;
    this.memePageSize = PAGE_SIZE_DEFAULT;
    this.memeTotalRecord = 0;
    this.newsPageIndex = 1;
    this.newsPageSize = PAGE_SIZE_DEFAULT;
    this.newsTotalRecord = 0;
  }

  async searchPageMeme(isInit: boolean) {
    if(isInit || this.listMeme.length < this.memeTotalRecord){
      const request = {
        page_index: this.memePageIndex,
        page_size: this.memePageSize,
        search_text: this.searchText,
        tag_id: 0,
        is_hot: false
      };
      await this.spinner.show();
      await this.memeService.searchPage(request).then((res) => {
        if (res?.status) {
          const data = res.data;
          this.listMeme.push(...data.data);
          this.memeTotalRecord = data.total;
          this.memePageIndex++;
        }
      });
      await this.spinner.hide();
    }
  }

  async searchPageNews(isInit: boolean) {
    if(isInit || this.listNews.length < this.newsTotalRecord){
      const request = {
        page_index: this.newsPageIndex,
        page_size: this.newsPageSize,
        search_text: this.searchText,
        tag_id: 0,
        is_hot: false
      };
      await this.newsService.searchPage(request).then((res) => {
        if (res?.status) {
          const data = res.data;
          this.listNews.push(...data.data);
          this.newsTotalRecord = data.total;
          this.newsPageIndex++;
        }
      });
    }
  }

  async searchListTag() {
    await this.tagService.searchList({search_text: this.searchText}).then((res)=>{
      if(res?.status){
        this.listTag = res.data;
      }
    });
  }
}
