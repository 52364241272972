import {Component, OnInit} from '@angular/core';
import {SeoService} from "../../services/seo.service";

@Component({
  selector: 'app-terms-privacy',
  templateUrl: './terms-privacy.component.html',
  styleUrls: ['./terms-privacy.component.css']
})
export class TermsPrivacyComponent implements OnInit {
  constructor(
    private seo: SeoService
  ) {
  }

  ngOnInit()  {
    this.seo.setMetaTermsPrivacy();
  }
}
