import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import { FbCommentsDirective } from './directive/fb-comments.directive';
import {NgxSpinnerModule} from "ngx-spinner";
import {ToastrModule} from "ngx-toastr";
import {ContactComponent} from "./pages/contact/contact.component";
import {TermsPrivacyComponent} from "./pages/terms-privacy/terms-privacy.component";
import {NotFoundComponent} from "./pages/not-found/not-found.component";
import {ListMemeComponent} from "./component/list-meme/list-meme.component";
import {ListMemeRelatedComponent} from "./component/list-meme-related/list-meme-related.component";
import {DownloadMemeComponent} from "./component/download-meme/download-meme.component";
import {HomeComponent} from "./pages/home/home.component";
import {MemeComponent} from "./pages/meme/meme.component";
import {SearchComponent} from "./pages/search/search.component";
import {TagComponent} from "./pages/tag/tag.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ListNewsComponent} from "./component/list-news/list-news.component";
import {ListNewsRelatedComponent} from "./component/list-news-related/list-news-related.component";
import {NewsDetailComponent} from "./pages/news/news-detail/news-detail.component";
import {NewsListComponent} from "./pages/news/news-list/news-list.component";
import {SanitizeHtmlPipe} from "./component/pipe/sanitize-html.pipe";
import {ListShopeeComponent} from "./component/list-shopee/list-shopee.component";
import {SeoService} from "./services/seo.service";
import {ListAdsComponent} from "./component/list-ads/list-ads.component";
import {CarouselModule} from "ngx-owl-carousel-o";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MemeComponent,
    SearchComponent,
    TagComponent,
    ContactComponent,
    TermsPrivacyComponent,
    NewsDetailComponent,
    NewsListComponent,
    NotFoundComponent,
    ListNewsComponent,
    ListNewsRelatedComponent,
    ListMemeComponent,
    ListMemeRelatedComponent,
    ListShopeeComponent,
    ListAdsComponent,
    DownloadMemeComponent,
    FbCommentsDirective,
    SanitizeHtmlPipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BrowserModule.withServerTransition({appId: 'MemeChua'}),
    AppRoutingModule,
    RouterModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    CarouselModule
  ],
  providers: [
    SeoService
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
