import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {NotFoundComponent} from "./pages/not-found/not-found.component";
import {TermsPrivacyComponent} from "./pages/terms-privacy/terms-privacy.component";
import {ContactComponent} from "./pages/contact/contact.component";
import {SearchComponent} from "./pages/search/search.component";
import {TagComponent} from "./pages/tag/tag.component";
import {MemeComponent} from "./pages/meme/meme.component";
import {HomeComponent} from "./pages/home/home.component";
import {NewsDetailComponent} from "./pages/news/news-detail/news-detail.component";
import {NewsListComponent} from "./pages/news/news-list/news-list.component";

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'meme/:slug', component: MemeComponent },
  { path: 'tag/:slug', component: TagComponent },
  { path: 'tin-tuc', component: NewsListComponent },
  { path: 'tin-tuc/:slug', component: NewsDetailComponent },
  { path: 'search/:searchText', component: SearchComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'terms-privacy', component: TermsPrivacyComponent },
  { path: 'not-found', component: NotFoundComponent },
  // Otherwise, redirect to not found page
  { path: '**', redirectTo: '/not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
