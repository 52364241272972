
<div class="col-sm-12">
    <div class="card card-block card-stretch mb-0">
        <div class="card-header d-flex justify-content-between">
            <div class="header-title">
                <h4 class="card-title fw-bold">{{title}}</h4>
            </div>
        </div>
    </div>
</div>
<div class="col-sm-12" *ngFor="let item of listNewsRelated">
    <a [routerLink]="['/tin-tuc/' + item.slug]">
        <div class="card card-block card-stretch mb-0">
            <div class="card-header">
                <div class="d-flex align-items-center mb-3">
                    <div class="stories-data">
                        <h5 class="fw-bold">{{item.title}}</h5>
                    </div>
                </div>
                <div class="image-container w-100">
                    <img [src]="apiUrl + '/' + item.image" alt="{{item.content}}"
                        class="img-fluid rounded w-100">
                    <div class="overlay"></div>
                </div>
            </div>
        </div>
    </a>
</div>
