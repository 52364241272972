import {Component, OnInit} from '@angular/core';
import {ShopeeService} from "../../services/shopee.service";
import {DeviceService} from "../../services/device.service";
import {OwlOptions} from "ngx-owl-carousel-o";
@Component({
  selector: 'list-shopee',
  templateUrl: './list-shopee.component.html',
  styleUrls: ['./list-shopee.component.css']
})
export class ListShopeeComponent implements OnInit {
  listShopee: any = [];
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
  }

  constructor(
    private shopeeService: ShopeeService,
    private deviceService: DeviceService,
  ) {
  }

  ngOnInit() {
    const listShopee = this.shopeeService.getListShopee();
    for (let i = 0; i < 9; i++) {
      this.listShopee = this.listShopee.concat(listShopee);
    }
  }

  isMobile() {
    return this.deviceService.isMobile();
  }

  handleClick(slide: any) {
    console.log(slide);
  }
}
