<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <div class="header-title">
          <h4 class="card-title fw-bold">Thông tin liên hệ</h4>
        </div>
      </div>
      <div class="card-body">
        <p>Fanpage: <a href="https://www.facebook.com/memechuavn" class="text-danger" aria-label="Gmail"
                       target="_blank">https://www.facebook.com/memechuavn</a></p>
        <p>Twitter(X): <a href="https://twitter.com/MemeChuaVN" class="text-danger" aria-label="Gmail"
                          target="_blank">https://twitter.com/MemeChuaVN</a></p>
        <p>Youtube: <a href="https://www.youtube.com/@MemeChuaChamCom" class="text-danger" aria-label="Gmail"
                       target="_blank">https://www.youtube.com/@MemeChuaChamCom</a></p>
        <p>Mọi thông tin đóng góp bài viết, báo lỗi, liên hệ công việc...vui lòng gửi email đến: <a
          href="mailto:memechua.contact@gmail.com" class="text-danger" aria-label="Gmail"
          target="_blank">memechua.contact@gmail.com</a></p>
      </div>
    </div>
  </div>
</div>
