<div class="row">
    <div class="col-lg-9">
        <div class="col-sm-12">
            <div class="card card-block card-stretch mb-0">
                <div class="card-header d-flex justify-content-between">
                    <div class="header-title">
                        <h4 class="card-title fw-bold">Tin tức</h4>
                    </div>
                </div>
            </div>
        </div>
        <list-news [listNews]="listNews" [total]="totalRecord" (onShowMore)="searchPage(false)"></list-news>
    </div>
    <div class="col-lg-3">
        <list-news-related [listNewsRelated]="listNewsHot" [title]="'Tin tức Hot'"></list-news-related>
    </div>
</div>
