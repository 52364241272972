<div class="row">
  <div class="col-lg-12">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-block card-stretch mb-0">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title fw-bold">Kết quả tìm kiếm cho: {{ searchText }}
                ({{ memeTotalRecord + newsTotalRecord + listTag.length }}
                kết quả)</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="listTag.length > 0">
        <div class="card card-block card-stretch">
          <div class="card-body">
            <div class="user-post">
                        <span *ngFor="let tag of listTag">
                            <a class="btn bg-soft-primary me-1 mb-1"
                               [routerLink]="['/tag/' + tag.slug]">#{{ tag.name }}</a>
                        </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <list-meme [listMeme]="listMeme" [total]="memeTotalRecord" (onShowMore)="searchPageMeme(false)"></list-meme>
      </div>
      <div class="col-md-6">
        <list-news [listNews]="listNews" [total]="newsTotalRecord" (onShowMore)="searchPageNews(false)"></list-news>
      </div>
    </div>
  </div>
</div>
