import {Injectable} from "@angular/core";
import {ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: 'root',
})
export class ShopeeService {
  listShopee = [
    {
      id: 1,
      name: 'Mặt nạ che bịt mắt ngủ hình ếch xanh Pepe siêu bựa dành cho vozer',
      image: '/assets/images/shopee/shopee1.jpg',
      link: 'https://shope.ee/5pluBHksd0'
    },
    {
      id: 2,
      name: 'Túi đeo chéo nhỏ mặt ếch xanh Pepe sad frog siêu bựa dành cho vozer',
      image: '/assets/images/shopee/shopee2.jpg',
      link: 'https://shope.ee/8KTFA69dkl'
    },
    {
      id: 3,
      name: 'Thiệp sinh nhật, chúc mừng cảm ơn, Giáng sinh Noel Christmas Valentine tình yêu ếch xanh Pepe siêu bựa cho vozer',
      image: '/assets/images/shopee/shopee3.jpg',
      link: 'https://shope.ee/2AsbonNVc6'
    },
  ];

  constructor(
  ) {
  }

  getListShopee() {
    return this.listShopee;
  }
}
