import {AfterViewInit, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MemeService} from '../../services/meme.service';
import {environment} from '../../../environments/environment';
import {SeoService} from "../../services/seo.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {isPlatformBrowser} from "@angular/common";
import {DeviceService} from "../../services/device.service";

@Component({
  selector: 'app-meme',
  templateUrl: './meme.component.html',
  styleUrls: ['./meme.component.css']
})
export class MemeComponent implements OnInit, AfterViewInit {
  currentUrl: string = environment.mainUrl;
  apiUrl: string = environment.apiUrl;
  memeDetail: any;
  listMemeRelated: any = [];

  constructor(
    private memeService: MemeService,
    private seo: SeoService,
    private deviceService: DeviceService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
  }

  async ngOnInit() {
    this.route.paramMap.subscribe(async params => {
      await this.spinner.show();
      const slug = params.get('slug');
      if (slug) {
        const data = await this.detailBySlug(slug);
        if (data) {
          this.memeDetail = data;
          this.seo.setMetaMeme(this.memeDetail?.name, this.memeDetail?.slug, this.apiUrl + '/' + this.memeDetail?.image);
          this.searchPageMemeRelated();
        } else {
          await this.router.navigate(['/not-found']);
        }
      }
      await this.spinner.hide();
    });
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.currentUrl = window.location.href;
    } else {
      this.currentUrl = environment.mainUrl;
    }
  }

  searchPageMemeRelated() {
    const request = {
      page_index: 1,
      page_size: 10,
      search_text: '',
      tag_id: 0,
      is_hot: false,
      tag_ids: this.memeDetail?.tags?.map((i: any) => i.id).toString()
    };
    this.memeService.searchPage(request).then((res) => {
      if (res?.status) {
        this.listMemeRelated = res.data.data;
        this.listMemeRelated = this.listMemeRelated.filter((i: any) => i.id != this.memeDetail?.id);
      }
    });
  }

  async detailBySlug(slug: string) {
    if (slug) {
      let res = await this.memeService.detailBySlug(slug);
      if(res && res?.status){
        return res.data;
      } else {
        return null;
      }
    }
  }

  isMobile() {
    return this.deviceService.isMobile();
  }
}
