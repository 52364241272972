import {Component, OnInit} from '@angular/core';
import { MemeService } from '../../services/meme.service';
import { TagService } from '../../services/tag.service';
import {PAGE_SIZE_DEFAULT, TAG_TYPE} from "../../constants/config";
import {NewsService} from "../../services/news.service";
import {SeoService} from "../../services/seo.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css']
})
export class TagComponent implements OnInit {
  pageIndex: number = 1;
  pageSize: number = PAGE_SIZE_DEFAULT;
  totalRecord: number = 0;
  tagDetail: any;
  listItem: any = [];
  listItemHot: any = [];

  constructor(
    private tagService: TagService,
    private memeService: MemeService,
    private newsService: NewsService,
    private seo: SeoService,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(async params => {
      this.resetData();
      const slug = params.get('slug');
      if (slug) {
        const data = await this.detailBySlug(slug);
        if (data) {
          this.tagDetail = data;
          this.seo.setMetaTag(this.tagDetail?.name, this.tagDetail?.slug);
          await this.searchPageItem(true);
          await this.searchPageItemHot();
        } else {
          await this.router.navigate(['/not-found']);
        }
      }
    });
  }

  resetData() {
    this.listItem = [];
    this.listItemHot = [];
    this.pageIndex = 1;
    this.pageSize = PAGE_SIZE_DEFAULT;
    this.totalRecord = 0;
  }

  async searchPageItem(isInit: boolean) {
    if(isInit || this.listItem.length < this.totalRecord){
      const request = {
        page_index: this.pageIndex,
        page_size: this.pageSize,
        search_text: '',
        tag_id: this.tagDetail?.id,
        is_hot: false
      };
      let searchService = this.memeService;
      if(this.tagDetail?.type == TAG_TYPE.NEWS){
        searchService = this.newsService;
      }
      this.spinner.show();
      await searchService.searchPage(request).then((res) => {
        if (res?.status) {
          const data = res.data;
          this.listItem.push(...data.data);
          this.totalRecord = data.total;
          this.pageIndex++;
        }
      });
      this.spinner.hide();
    }
  }

  async searchPageItemHot() {
    const request = {
      page_index: 1,
      page_size: 10,
      search_text: '',
      tag_id: 0,
      is_hot: true
    };
    let searchService = this.memeService;
    if(this.tagDetail?.type == TAG_TYPE.NEWS){
      searchService = this.newsService;
    }
    await searchService.searchPage(request).then((res) => {
      if (res?.status) {
        this.listItemHot = res.data.data;
      }
    });
  }

  async detailBySlug(slug: string) {
    if (slug) {
      let res = await this.tagService.detailBySlug(slug);
      if(res && res?.status){
        return res.data;
      } else {
        return null;
      }
    }
  }

  protected readonly TAG_TYPE = TAG_TYPE;
}
