import {Component, OnInit} from '@angular/core';
import { SeoService } from 'src/app/services/seo.service';
import {NewsService} from "../../../services/news.service";
import {PAGE_SIZE_DEFAULT} from "../../../constants/config";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-tag',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.css']
})
export class NewsListComponent implements OnInit {
  slug: any;
  listNews: any = [];
  listNewsHot: any = [];
  pageIndex: number = 1;
  pageSize: number = PAGE_SIZE_DEFAULT;
  totalRecord: number = 0;

  constructor(
    private newsService: NewsService,
    private seo: SeoService,
    private spinner: NgxSpinnerService,
  ) {
  }

  async ngOnInit() {
    this.seo.setMetaNewsList();
    this.resetData();
    await this.searchPage(true);
    await this.searchPageNewsHot();
  }

  resetData() {
    this.listNews = [];
    this.listNewsHot = [];
    this.pageIndex = 1;
    this.pageSize = PAGE_SIZE_DEFAULT;
    this.totalRecord = 0;
  }

  async searchPage(isInit: boolean) {
    if(isInit || this.listNews.length < this.totalRecord){
      const request = {
        page_index: this.pageIndex,
        page_size: this.pageSize,
        search_text: '',
        tag_id: 0,
        is_hot: false
      };
      await this.spinner.show();
      await this.newsService.searchPage(request).then((res) => {
        if (res?.status) {
          const data = res.data;
          this.listNews.push(...data.data);
          this.totalRecord = data.total;
          this.pageIndex++;
        }
      });
      await this.spinner.hide();
    }
  }

  async searchPageNewsHot() {
    const request = {
      page_index: 1,
      page_size: 10,
      search_text: '',
      tag_id: 0,
      is_hot: true
    };
    await this.newsService.searchPage(request).then((res) => {
      if (res?.status) {
        this.listNewsHot = res.data.data;
      }
    });
  }
}
